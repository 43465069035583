.banner-content {
    animation: slideLeft 20s linear infinite;
}

.banner-content:hover {
    animation-play-state: paused;
}

@keyframes slideLeft {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(-100%);
    }
}
